
import { defineComponent } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useAuditListData';
import router from '@/router';
export default defineComponent({
  name: 'collectionAuditList',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const back = () => {
      router.push('/erp/financial/collection');
    };
    return {
      back,
      columnList,
      tableRef,
    };
  },
});
