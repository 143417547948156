import { ErpTableOptions } from '@/types/type';

import { ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '流水号',
      prop: 'flowNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'infund_audit_status',
    },
    {
      label: '交易时间',
      prop: 'transactionTime',
      value: [],
      minWidth: 160,
      type: 'time',
      filterProp: ['transactionTimeStartDate', 'transactionTimeEndDate'],
    },
    {
      label: ' 收款方名称',
      prop: 'principalFullName',
      value: '',
      type: 'input',
      minWidth: 120,
    },
    {
      label: '收款金额',
      prop: 'amount',
      propDesc: 'amountDesc',
      minWidth: 140,
      value: [],
      type: 'number',
      filterProp: ['amountMinVal', 'amountMaxVal'],
    },
    {
      label: '摘要',
      prop: 'remark',
      value: '',
      type: 'input',
      minWidth: 120,
    },
    {
      label: '付方名称',
      prop: 'payerName', // actualWeightSum
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '付方账号',
      prop: 'payerAccountName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '付方开户行名',
      prop: 'payerBankName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '收款类型',
      prop: 'infundType',
      propDesc: 'infundTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'infund_type',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '销售结算单号',
      prop: 'sellStmtNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '杂费类型',
      prop: 'sundryFeeType',
      propDesc: 'sundryFeeTypeDesc',
      minWidth: 160,
      value: [],
      type: 'multiSelect',
      options: 'sundry_fee_type_payment',
    },
    {
      label: '核销金额',
      prop: 'actualAmount',
      propDesc: 'actualAmountDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['actualAmountMinVal', 'actualAmountMaxVal'],
    },
    {
      label: '备注',
      prop: 'orderRemark',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 160,
      value: '',
      type: 'input',
    },
  ];
  return { columnList, tableRef };
};
